import { Social } from '@/shared/ui/social';
import type { TFunction } from 'i18next';

export const contactsItems = (t: TFunction) => [
  {
    align: 'flex-start',
    label: t('contacts.phone'),
    value: '+40749559694',
  },
  {
    align: 'center',
    label: t('contacts.social_networks'),
    value: <Social />,
  },
];
